import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

/**
 * HTML / CSS only menu for SEO
 */
export const FallbackMenu = (props) => {
  const { navigations } = props;

  return (
    <noscript>
      <Stack
        sx={{
          position: "relative",
          "#navbar:target": { display: "flex" },
        }}
      >
        <Stack
          component="ul"
          id="navbar"
          sx={{
            display: "none",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            m: 0,
            zIndex: 5000,
            background: "white",
            p: "20px",
          }}
        >
          <Stack component="li" sx={{ alignSelf: "end", mb: "20px" }}>
            <a href="#">close</a>
          </Stack>
          {navigations?.map((item, i) => (
            <Stack component="li" key={item?.url || i}>
              <a href={item?.url}>{item?.label}</a>
            </Stack>
          ))}
          <Stack component="li" sx={{ mt: "20px" }}>
            <a href="/contact">Contact</a>
          </Stack>
        </Stack>
      </Stack>
    </noscript>
  );
};

export const FallbackMenuTrigger = (props) => {
  const { children } = props;

  return (
    <Stack sx={{ position: "relative" }}>
      {children}
      <noscript>
        <Box
          component="a"
          href="#navbar"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        />
      </noscript>
    </Stack>
  );
};
