import { magentoMenuToNavigation } from "@graphcommerce/magento-category";

import {
  LayoutDefault,
  LayoutDefaultProps,
  NavigationFab,
  NavigationProvider,
  useMemoDeep,
  useNavigationSelection,
} from "@graphcommerce/next-ui";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isSafari } from "react-device-detect";
import { NavigationOverlay } from "../../@graphcommerce/next-ui/Navigation/components/NavigationOverlay";
import FooterLayout from "../Footer/FooterLayout";
import Header from "../Header/Header";
import StickyCart from "../Header/StickyCart";
import { LayoutQuery } from "./Layout.gql";

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, "footer" | "header" | "cartFab" | "menuFab">;

export function LayoutNavigation(props: LayoutNavigationProps) {
  const {
    getLegalLinks,
    getSocialLinks,
    reinsuranceLink,
    menu,
    storeConfig,
    navigations,
    partners,
    children,
    links,
    otherLinks,
    ...uiProps
  } = props;

  const selection = useNavigationSelection();
  const router = useRouter();
  const selectionHandler = () => {
    selection.set([menu?.items?.[0]?.uid || ""]);
  };
  const [safariWidth, setSafariWidth] = useState("max-content !important");

  useEffect(() => {
    const checkSafari = isSafari
      ? "fit-content !important"
      : "max-content !important";
    setSafariWidth(checkSafari);
  }, []);
  return (
    <>
      <Box
        sx={{
          ".LayoutOverlayBase-overlayPane": {
            width: safariWidth,
          },
        }}
      >
        <NavigationProvider
          selection={selection}
          items={useMemoDeep(
            () => [...magentoMenuToNavigation(menu, true)],
            [menu],
          )}
        >
          <NavigationOverlay
            stretchColumns={false}
            justifySm="start"
            justifyMd="start"
            itemWidthSm="100vw"
            itemWidthMd="400px"
            variantSm="left"
            variantMd="left"
            sizeSm="full"
            sizeMd="full"
            mouseEvent="hover"
          />
        </NavigationProvider>
      </Box>

      <LayoutDefault
        {...uiProps}
        noSticky={router.asPath.split("?")[0] === "/"}
        header={
          <>
            <Header
              selectionHandler={selectionHandler}
              menu={menu}
              navigations={navigations}
            />
            <div></div>
          </>
        }
        footer={
          <FooterLayout
            partners={partners}
            getLegalLinks={getLegalLinks}
            getSocialLinks={getSocialLinks}
            storeConfig={storeConfig}
            links={links}
            otherLinks={otherLinks}
            reinsuranceLink={reinsuranceLink}
          />
        }
        menuFab={
          <NavigationFab
            onClick={() => selection.set([menu?.items?.[0]?.uid || ""])}
          />
        }
        cartFab={<StickyCart />}
        sx={{
          header: {
            alignItems: { md: "start" },
            height: "auto",
            marginBottom: "14px",
          },
        }}
      >
        {children}
      </LayoutDefault>
    </>
  );
}
