import { SvgIcon } from "@mui/material";

export default function User(props) {
  return (
    <SvgIcon {...props} width="28" height="29" viewBox="0 0 28 29" fill="none">
      <g fill="white">
        <path
          d="M22.1666 25.375V22.9583C22.1666 21.6765 21.675 20.4471 20.7998 19.5407C19.9246 18.6342 18.7377 18.125 17.5 18.125H10.5C9.2623 18.125 8.07532 18.6342 7.20015 19.5407C6.32498 20.4471 5.83331 21.6765 5.83331 22.9583V25.375"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 13.2917C16.5773 13.2917 18.6666 11.1277 18.6666 8.45833C18.6666 5.78896 16.5773 3.625 14 3.625C11.4227 3.625 9.33331 5.78896 9.33331 8.45833C9.33331 11.1277 11.4227 13.2917 14 13.2917Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
