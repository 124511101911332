import { Link } from "@mui/material";
import { useRouter } from "next/router";

export const ObfuscatedLink = (props) => {
  const { noHover, href, children, sx, ...rest } = props;
  const router = useRouter();

  const handleClick = (e) => {
    e.preventDefault();
    router.push(href);
  };

  return (
    <Link
      {...rest}
      sx={{ ...(!noHover && { "&:hover": { color: "primary.main" } }), ...sx }}
      component="button"
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};
