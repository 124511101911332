import { useMemo } from "react";
import { window } from "browser-monads-ts";

const win = window as any;

export const useSearchParam = () => {
  const pathname = useMemo(() => {
    const search = win.location.pathname
      .split("/")
      ?.reduce((acc, curr, i, arr) => (arr[1] === "search" ? arr[2] : ""), "");
    if (!search || typeof search !== "string") return "";
    return win.decodeURIComponent(search);
  }, [win.location.pathname]);

  return pathname;
};
