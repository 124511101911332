import { SvgIcon } from "@mui/material";

export default function ForkliftGray(props) {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30" fill="none">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100.000000pt"
        height="100.000000pt"
        viewBox="0 0 100.000000 100.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
          fill="#4d4d4d"
          stroke="none"
        >
          <path
            d="M637 944 c-4 -4 -7 -63 -7 -131 0 -67 -3 -123 -8 -123 -4 0 -39 32
        -77 70 l-69 70 -86 0 c-97 0 -125 -11 -157 -59 -20 -30 -23 -47 -23 -131 l0
        -97 -40 -12 c-70 -21 -80 -50 -80 -224 l0 -149 34 -34 c28 -28 41 -34 77 -34
        50 0 104 28 114 59 5 18 15 21 60 21 45 0 55 -3 60 -21 10 -31 64 -59 114 -59
        38 0 49 5 82 40 l38 39 138 3 138 3 3 29 c2 16 -2 32 -10 37 -7 5 -64 9 -125
        9 l-113 0 -2 348 -3 347 -25 3 c-14 2 -29 0 -33 -4z m-100 -286 l93 -93 0
        -131 0 -132 -39 15 c-55 22 -102 11 -140 -32 -26 -30 -37 -35 -76 -35 -39 0
        -49 5 -79 37 -29 32 -40 37 -82 39 l-49 2 -3 46 c-6 85 -5 86 183 86 135 0
        166 3 179 16 20 19 20 29 0 48 -12 13 -39 16 -125 16 l-109 0 0 93 c0 59 4 97
        12 105 7 7 40 12 77 12 l66 0 92 -92z m-299 -420 c21 -21 14 -57 -13 -70 -22
        -10 -29 -9 -47 10 -19 18 -20 25 -10 47 13 27 49 34 70 13z m344 -13 c10 -22
        9 -29 -10 -47 -18 -19 -25 -20 -47 -10 -16 8 -25 20 -25 35 0 50 62 66 82 22z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
