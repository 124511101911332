import ForkliftGray from "@/components/smbois/Icons/ForkliftGray";
import Logo from "@/components/smbois/Icons/Logo";
import GreyShoppingCart from "@/components/smbois/Icons/TabBar/GreyShoppingCart";
import {
  dvw,
  useMotionSelector,
  useMotionValueValue,
} from "@graphcommerce/framer-utils";
import { CartFab } from "@graphcommerce/magento-cart";
import { WishlistFab } from "@graphcommerce/magento-wishlist";
import { IconSvg, useIconSvgSize } from "@graphcommerce/next-ui/IconSvg";
import { LayoutHeaderContent } from "@graphcommerce/next-ui/Layout/components/LayoutHeaderContent";
import { mouseEventPref } from "@graphcommerce/next-ui/Navigation/components/NavigationItem";
import { NavigationList } from "@graphcommerce/next-ui/Navigation/components/NavigationList";
import { useNavigation } from "@graphcommerce/next-ui/Navigation/hooks/useNavigation";
import { OverlaySsr } from "@graphcommerce/next-ui/Overlay/components/OverlaySsr";
import { extendableComponent } from "@graphcommerce/next-ui/Styles/extendableComponent";
import { useFabSize } from "@graphcommerce/next-ui/Theme";
import { useMatchMedia } from "@graphcommerce/next-ui/hooks";
import { iconChevronLeft, iconClose } from "@graphcommerce/next-ui/icons";
import { i18n } from "@lingui/core";
import {
  Box,
  Divider,
  Fab,
  IconButton,
  Link,
  Stack,
  SxProps,
  Theme,
  Typography,
  styled,
  useEventCallback,
  useTheme,
} from "@mui/material";
import { m } from "framer-motion";
import React, { useEffect } from "react";
import type { LiteralUnion } from "type-fest";

type LayoutOverlayVariant = "left" | "bottom" | "right";
type LayoutOverlaySize = "floating" | "minimal" | "full";
type LayoutOverlayAlign = "start" | "end" | "center" | "stretch";
type ItemPadding = LiteralUnion<keyof Theme["spacings"], string | number>;

type NavigationOverlayProps = {
  sx?: SxProps<Theme>;
  stretchColumns?: boolean;
  variantSm: LayoutOverlayVariant;
  variantMd: LayoutOverlayVariant;
  sizeSm?: LayoutOverlaySize;
  sizeMd?: LayoutOverlaySize;
  justifySm?: LayoutOverlayAlign;
  justifyMd?: LayoutOverlayAlign;
  itemWidthSm?: string;
  itemWidthMd?: string;
  itemPadding?: ItemPadding;
  forceOpen?: boolean;
} & mouseEventPref;

const MotionDiv = styled(m.div)({});

const componentName = "Navigation";
const parts = ["root", "navigation", "header", "column"] as const;
const { classes } = extendableComponent(componentName, parts);

export const NavigationOverlay = React.memo((props: NavigationOverlayProps) => {
  const {
    sx,
    stretchColumns,
    variantMd,
    variantSm,
    justifyMd,
    justifySm,
    sizeMd,
    sizeSm,
    itemWidthSm = dvw(100),
    itemWidthMd,
    mouseEvent,
    itemPadding = "md",
    forceOpen,
  } = props;
  const {
    selection,
    items,
    animating,
    closing,
    serverRenderDepth,
    animationDuration,
  } = useNavigation();

  const fabMarginY = `calc((${useFabSize("responsive")} - ${useIconSvgSize(
    "large",
  )}) * -0.5)`;
  const theme = useTheme();
  const itemPad = useTheme().spacings[itemPadding] ?? itemPadding;
  const matchMedia = useMatchMedia();

  const handleOnBack = useEventCallback(() => {
    if (matchMedia.down("md")) {
      const current = selection.get();
      selection.set(current !== false ? current.slice(0, -1) : false);
    } else selection.set([]);
  });

  const selectedLevel = useMotionValueValue(selection, (s) =>
    s === false ? -1 : s.length,
  );
  const selectionValue = useMotionValueValue(selection, (s) =>
    s ? s.join("") : s,
  );
  const activeAndNotClosing = useMotionSelector(
    [selection, closing],
    ([s, c]) => (c ? false : s !== false),
  );

  useEffect(() => {
    animating.set(true);
  }, [activeAndNotClosing, animating]);

  const afterClose = useEventCallback(() => {
    if (!closing.get()) return;
    setTimeout(() => {
      closing.set(false);
    }, animationDuration * 1000);
    selection.set(false);
  });

  const handleClose = useEventCallback(() => closing.set(true));

  if (selectedLevel === -1 && serverRenderDepth <= 0) return null;

  return (
    <OverlaySsr
      className={classes.root}
      active={forceOpen || activeAndNotClosing}
      safeToRemove={afterClose}
      onClosed={handleClose}
      variantSm={variantSm}
      sizeSm={sizeSm}
      justifySm={justifySm}
      variantMd={variantMd}
      sizeMd={sizeMd}
      justifyMd={justifyMd}
      widthMd={false}
      widthSm={false}
      overlayPaneProps={{
        layout: true,
        layoutDependency: selectionValue,
        initial: false,
        onLayoutAnimationStart: () => {
          animating.set(true);
        },
        onLayoutAnimationComplete: () => {
          animating.set(false);
        },
      }}
      sx={{ zIndex: "drawer", "& .Navigation-header": { height: "100px" } }}
    >
      <MotionDiv
        layout
        layoutDependency={selectionValue}
        sx={{ display: "grid" }}
      >
        <Box
          className={classes.header}
          sx={(theme) => ({
            top: 0,
            position: "sticky",
            "& .LayoutHeaderContent-bg": {
              boxShadow: "none !important",
            },
            height: {
              xs: theme.appShell.headerHeightSm,
              md: theme.appShell.appBarHeightMd,
            },
            zIndex: 1,
          })}
        >
          <LayoutHeaderContent
            floatingMd={false}
            floatingSm={false}
            switchPoint={0}
            sx={{
              marginBottom: "15px",
            }}
            layout="position"
            layoutDependency={selectionValue}
            left={
              <Stack
                sx={{
                  mt: "24px",
                  mb: "32px",
                  ml: { xs: "9px", md: "0" },
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <IconButton
                  sx={{
                    height: "52px",
                    width: "65px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                  href="/"
                  color="primary"
                >
                  <Logo sx={{ height: "52px", width: "65px" }} />
                </IconButton>
              </Stack>
            }
            right={
              <Stack direction="row">
                {selectedLevel > 1 && (
                  <Fab
                    color="inherit"
                    onClick={handleOnBack}
                    sx={[
                      (theme) => ({
                        display: "none",
                        [theme.breakpoints.down("md")]: {
                          display: "inline-flex",
                        },
                        mr: { xs: "40px", md: "0" },
                        boxShadow: "none",
                        my: fabMarginY,
                      }),
                    ]}
                    size="responsive"
                    aria-label={i18n._(/* i18n */ "Back")}
                  >
                    <IconSvg src={iconChevronLeft} size="large" aria-hidden />
                  </Fab>
                )}
                <Fab
                  color="inherit"
                  onClick={handleClose}
                  sx={[
                    () => ({
                      boxShadow: "none",
                      my: fabMarginY,
                      mr: { xs: "20px", md: "5px" },
                    }),
                  ]}
                  size="responsive"
                  aria-label={i18n._(/* i18n */ "Close")}
                >
                  <IconSvg src={iconClose} size="large" aria-hidden />
                </Fab>
              </Stack>
            }
          />
        </Box>
      </MotionDiv>
      <MotionDiv
        layout="position"
        layoutDependency={selectionValue}
        sx={{ display: "grid" }}
      >
        <Box
          sx={[
            (theme) => ({
              display: "grid",
              alignItems: !stretchColumns ? "start" : undefined,
              justifyContent: "end",
              [theme.breakpoints.down("md")]: {
                width:
                  sizeSm !== "floating"
                    ? itemWidthSm
                    : `calc(${itemWidthSm} - (${theme.page.horizontal} * 2))`,
                minWidth: 200,
                overflow: "hidden",
                scrollSnapType: "x mandatory",
                "& .NavigationItem-item": {
                  width:
                    sizeSm !== "floating"
                      ? `calc(${itemWidthSm} - (${itemPad} * 2))`
                      : `calc(${itemWidthSm} - (${itemPad} * 2) - (${theme.page.horizontal} * 2))`,
                  minWidth: `calc(200px - (${itemPad} * 2))`,
                },
              },
              [theme.breakpoints.up("md")]: {
                "& .NavigationItem-item": {
                  width: itemWidthMd || "stretch",
                },
              },
            }),
          ]}
        >
          <Box
            className={classes.navigation}
            sx={[
              {
                display: "grid",
                gridAutoFlow: "column",
                scrollSnapAlign: "end",
                "& > ul > li > a, & > ul > li > [role=button]": {
                  "& span": {
                    typography: "h2",
                  },
                  // '& svg': { display: 'none' },
                },
                "& .Navigation-column": {},
                "& .NavigationItem-item": {
                  minHeight: "54px",
                  whiteSpace: "nowrap",
                  "&.Mui-disabled": {
                    opacity: 1,
                    backgroundColor: "#ffe8eb",
                  },
                  px: "50px",
                  [theme.breakpoints.down("md")]: {
                    width: "100vw",
                  },
                },
                "& .NavigationItem-item: hover": {
                  color: `${theme.palette.text.secondary} !important`,
                  backgroundColor: "#ffe8eb !important",
                },
                "& .NavigationItem-item.first": {},
                "& .Navigation-column:first-of-type": {
                  boxShadow: "none",
                },
              },

              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          >
            {selectedLevel >= 0 && (
              <Box
                sx={(theme) => ({
                  gridArea: "1 / 1 / 999 / 2",
                  boxShadow: `inset 1px 0 ${theme.palette.divider}`,
                })}
                className={classes.column}
              />
            )}
            {selectedLevel >= 1 && (
              <Box
                sx={(theme) => ({
                  gridArea: "1 / 2 / 999 / 3",
                  boxShadow: `inset 1px 0 ${theme.palette.divider}`,
                })}
                className={classes.column}
              />
            )}
            {selectedLevel >= 2 && (
              <Box
                sx={(theme) => ({
                  gridArea: "1 / 3 / 999 / 4",
                  boxShadow: `inset 1px 0 ${theme.palette.divider}`,
                })}
                className={classes.column}
              />
            )}
            {selectedLevel >= 3 && (
              <Box
                sx={(theme) => ({
                  gridArea: "1 / 4 / 999 / 5",
                  boxShadow: `inset 1px 0 ${theme.palette.divider}`,
                })}
                className={classes.column}
              />
            )}

            <NavigationList items={items} selected mouseEvent={mouseEvent} />
          </Box>
          <Divider variant="middle" sx={{ my: "40px", mx: "50px" }} />
          <Stack sx={{ ml: "-15px", px: "50px" }}>
            <Stack direction="row" spacing="3px" sx={{ alignItems: "center" }}>
              <WishlistFab
                icon={<ForkliftGray sx={{ width: "24px", height: "24px" }} />}
              />
              <Link
                href="/wishlist"
                sx={{
                  textDecoration: "none",
                  color: theme.palette.text.secondary,
                  "&:active": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <Typography sx={{ color: "inherit" }}>
                  Ma liste de chantier
                </Typography>
              </Link>
            </Stack>
            <Stack
              direction="row"
              spacing={{ xs: "11.5px", md: "3.5px" }}
              sx={{
                alignItems: "center",
                color: theme.palette.text.secondary,
                "& .CartFab-shadow": {
                  boxShadow: "none !important",
                },
              }}
            >
              <CartFab
                sx={{
                  [theme.breakpoints.down("md")]: {
                    pl: "4px !important",
                    pr: "5px",
                  },
                }}
                icon={
                  <GreyShoppingCart
                    sx={{
                      color: theme.palette.text.secondary,
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
              <Link
                href="/cart"
                sx={{
                  textDecoration: "none",
                  color: theme.palette.text.secondary,
                  "&:active": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <Typography sx={{ color: "inherit" }}>Mon Panier</Typography>
              </Link>
            </Stack>
            <Link
              href="/contact"
              align="left"
              underline="none"
              sx={{
                color: theme.palette.primary.main,
                display: "flex",
                whiteSpace: "nowrap",
                pl: "58px",
                mb: "50px",
                mt: "17px",
              }}
            >
              CONTACT
            </Link>
          </Stack>
        </Box>
      </MotionDiv>
    </OverlaySsr>
  );
});

if (process.env.NODE_ENV !== "production") {
  NavigationOverlay.displayName = "NavigationOverlay";
}
