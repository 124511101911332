import { useMotionValueValue } from "@graphcommerce/framer-utils";
import { CartFab } from "@graphcommerce/magento-cart";
import {
  extendableComponent,
  useFabAnimation,
  useScrollY,
} from "@graphcommerce/next-ui";
import { styled } from "@mui/material/styles";
import { m } from "framer-motion";
import ShoppingCart from "../Icons/TabBar/ShoppingCart";

const MotionDiv = styled(m.div)({});

const name = "MenuFab";
const parts = ["wrapper"] as const;
type OwnerState = {
  scrolled: boolean;
};
const { withState } = extendableComponent<
  OwnerState,
  typeof name,
  typeof parts
>(name, parts);

export default function StickyCart() {
  const { opacity } = useFabAnimation();
  const scrollY = useScrollY();
  const scrolled = useMotionValueValue(scrollY, (y) => y > 10);
  const classes = withState({ scrolled });
  return (
    <MotionDiv
      className={classes.wrapper}
      sx={(theme) => ({
        [theme.breakpoints.down("md")]: {
          opacity: "1 !important",
          transform: "none !important",
        },
      })}
      style={{ opacity }}
    >
      <CartFab icon={<ShoppingCart />} />
    </MotionDiv>
  );
}
