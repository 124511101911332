import { CartFab } from "@graphcommerce/magento-cart";
import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import { FallbackMenuTrigger } from "../FallbackMenu/FallbackMenu";
import BurgerMenu from "../Icons/TabBar/BurgerMenu";
import GreyShoppingCart from "../Icons/TabBar/GreyShoppingCart";
import Home from "../Icons/TabBar/Home";
import MagnifyingGlass from "../Icons/TabBar/MagnifyingGlass";
import User from "../Icons/TabBar/User";
import { ObfuscatedLink } from "../ObfuscatedLink/ObfuscatedLink";

export default function StickyBar(props) {
  const { selectionHandler, accountRef } = props;
  const theme = useTheme();
  const path = useRouter().asPath;

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: "0",
          left: "0",
          height: "70px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "5px",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Link
          href="/"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "5px",
            textDecoration: "none",
            color: theme.palette.text.primary,
            "&:active": {
              color: theme.palette.primary.main,
            },
            ...(path === "/" && { color: theme.palette.primary.main }),
          }}
        >
          <Home />
          <Typography sx={{ fontSize: "10px", color: "inherit" }}>
            Accueil
          </Typography>
        </Link>
        <ObfuscatedLink
          href="/search"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "5px",
            textDecoration: "none",
            color: theme.palette.text.primary,
            "&:active": {
              color: theme.palette.primary.main,
            },
            ...(path.includes("/search") && {
              color: theme.palette.primary.main,
            }),
          }}
        >
          <MagnifyingGlass sx={{ color: "inherit" }} />
          <Typography sx={{ fontSize: "10px", color: "inherit" }}>
            Recherche
          </Typography>
        </ObfuscatedLink>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "-0.5%",
            color: `${theme.palette.text.primary} !important`,
            "&:active": {
              color: `${theme.palette.primary.main} !important`,
            },
            "&:hover": {
              color: `${theme.palette.primary.main} !important`,
            },
          }}
        >
          <CartFab
            sx={{
              zIndex: 0,
              display: "flex",
              ".CartFab-shadow": {
                boxShadow: "0px 0px 0px",
                borderRadius: "0px",
              },
            }}
            icon={<GreyShoppingCart sx={{ color: "inherit" }} />}
          />
          <ObfuscatedLink
            href="/cart"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px",
              zIndex: "30",
              textDecoration: "none",
              color: "inherit",
              ...(path.includes("/cart") && {
                color: theme.palette.primary.main,
              }),
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                color: "inherit",
                transform: "translateY(-7px)",
              }}
            >
              Mon Panier
            </Typography>
          </ObfuscatedLink>
        </Stack>
        <ObfuscatedLink
          href={accountRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "5px",
            textDecoration: "none",
            color: theme.palette.text.primary,
            "&:active": {
              color: theme.palette.primary.main,
            },
            ...(path.includes(accountRef) && {
              color: theme.palette.primary.main,
            }),
          }}
        >
          <User />
          <Typography sx={{ fontSize: "10px", color: "inherit" }}>
            Compte
          </Typography>
        </ObfuscatedLink>
        <FallbackMenuTrigger>
          <Link
            component="div"
            onClick={() => selectionHandler()}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px",
              textDecoration: "none",
              color: theme.palette.text.primary,
              "&:active": {
                color: theme.palette.primary.main,
              },
            }}
          >
            <BurgerMenu />
            <Typography sx={{ fontSize: "10px", color: "inherit" }}>
              Menu
            </Typography>
          </Link>
        </FallbackMenuTrigger>
      </Box>
    </>
  );
}
