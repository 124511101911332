import { DesktopNavItem } from "@/components/@graphcommerce/next-ui/LayoutParts/DesktopNavBarItem";
import { useSearchParam } from "@/hooks/useSearchParam";
import { CartFab } from "@graphcommerce/magento-cart";
import {
  AccountDashboardDocument,
  useCustomerQuery,
} from "@graphcommerce/magento-customer";
import { SearchContext, SearchForm } from "@graphcommerce/magento-search";
import { WishlistFab } from "@graphcommerce/magento-wishlist";
import {
  DesktopNavActions,
  DesktopNavBar,
  FormRow,
  iconPerson,
  IconSvg,
} from "@graphcommerce/next-ui";
import { Trans } from "@lingui/react";
import {
  Box,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  FallbackMenu,
  FallbackMenuTrigger,
} from "../FallbackMenu/FallbackMenu";
import Forklift from "../Icons/Forklift";
import Logo from "../Icons/Logo";
import BurgerMenu from "../Icons/TabBar/BurgerMenu";
import MagnifyingGlass from "../Icons/TabBar/MagnifyingGlass";
import ShoppingCart from "../Icons/TabBar/ShoppingCart";
import { ObfuscatedLink } from "../ObfuscatedLink/ObfuscatedLink";
import StickyBar from "./StickyBar";

export default function Header(props) {
  const { navigations, selectionHandler } = props;
  const theme = useTheme();
  const dashboard = useCustomerQuery(AccountDashboardDocument, {
    fetchPolicy: "cache-and-network",
  });
  const customer = dashboard.data?.customer;
  const firstName = customer?.firstname;
  const lastName = customer?.lastname;

  const search = useSearchParam();
  const [displaySearch, setDisplaySearch] = useState<Boolean>(false);

  return (
    <SearchContext>
      <FallbackMenu navigations={navigations} />
      <IconButton
        href="/"
        color="primary"
        sx={{ display: { xs: "none", md: "flex" }, ml: "-25px" }}
        aria-label="Home"
      >
        <Logo
          sx={{
            alignSelf: "center",
            justifySelf: "center",
            width: { xs: "40px", md: "90px" },
            height: { xs: "55px", md: "100px" },
          }}
        />
      </IconButton>
      <Stack
        sx={{
          display: { md: "none" },
          height: "auto",
          minWidth: "100%",
          mb: "10px !important",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
          paddingBottom: "10px",
        }}
      >
        {!firstName && !lastName && (
          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
              color: "white",
              height: "26px",
              paddingTop: "8px",
              padding: "8px 15px 0",
              display: { sm: "flex", md: "none" },
            }}
            direction="row"
          >
            <Stack
              sx={{
                dicolor: "white",
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Link
                href="/contact"
                style={{
                  color: theme.palette.background.paper,
                  fontWeight: 500,
                  textDecoration: "underline",
                  textDecorationColor: "white",
                  marginRight: "7px",
                }}
              >
                CONTACTEZ-NOUS:
              </Link>

              <Link
                component="a"
                href="tel:0160260344"
                sx={{
                  display: "flex",
                  fontWeight: "bold",
                  justifyContent: "flex-end",
                  whiteSpace: "nowrap",
                  color: theme.palette.background.paper,
                  textDecoration: "none",
                }}
              >
                01 60 26 03 44
              </Link>
            </Stack>
          </Stack>
        )}

        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            paddingTop: "8px",
            padding: "8px 15px 0",
            display: { sm: "flex", md: "none" },
          }}
          direction="row"
          spacing="18px"
        >
          <Stack
            sx={{
              display: { sm: "flex", md: "none" },
              paddingTop: "8px",
              width: "50px",
            }}
          >
            <IconButton
              sx={{
                height: "50px",
                padding: "0 !important",
              }}
              href="/"
              color="primary"
              aria-label="Home"
            >
              <Logo
                sx={{
                  width: { xs: "40px", md: "75px" },
                  height: { xs: "55px", md: "105px" },
                }}
              />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: { sm: "flex", md: "none" },
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Typography
              sx={{
                color: "#000",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "20px",
                display: `${displaySearch ? "none" : "block"}`,
              }}
            >
              <Trans id="LE SPÉCIALISTE DU BOIS" />
            </Typography>
            <Box
              component="div"
              sx={{
                transform: "translateY(4px)",
                cursor: "pointer",
                display: `${displaySearch ? "none" : "block"}`,
              }}
              onClick={() => setDisplaySearch(true)}
            >
              <MagnifyingGlass sx={{ color: "#000" }} />
            </Box>
          </Stack>
          <FormRow
            sx={{
              flex: 1,
              width: "250px",
              transform: "translateX(-30px)",
              display: `${displaySearch ? "block" : "none"}`,
            }}
            onBlur={() => setDisplaySearch(false)}
          >
            <SearchForm
              search={search}
              sx={{ width: "250px" }}
              totalResults={0}
              textFieldProps={{
                autoFocus: true,
                fullWidth: true,
                "aria-label": "search",
              }}
            />
          </FormRow>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          display: { xs: "none", sm: "none", md: "flex" },
        }}
        direction="column"
      >
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "7px",
            display: { xs: "none", sm: "none", md: "flex" },
          }}
          direction="row"
        >
          <DesktopNavBar
            sx={{
              alignItems: "start",
              marginLeft: "-38px !important",
            }}
          >
            {navigations?.map((item) => (
              <DesktopNavItem
                underline="none"
                sx={{
                  color: `${theme.palette.text.primary} !important`,
                  "&:hover": {
                    color: `${theme.palette.primary.main} !important`,
                  },
                }}
                key={item?.label}
                href={`${item?.url}`}
              >
                {item?.label}
              </DesktopNavItem>
            ))}
          </DesktopNavBar>
          <DesktopNavActions>
            <Stack
              marginLeft="19px"
              marginTop="-8px"
              marginBottom="14px"
              paddingTop="1px"
              sx={{
                display: { sm: "none", md: "block" },
                justifyContent: "flex-end",
                marginBottom: "14px",
              }}
            >
              <Stack direction="row" alignItems="center">
                <ObfuscatedLink
                  href={customer ? "/account" : "/account/signin"}
                  underline="none"
                  noHover
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    my: "15px",
                    color: "text.primary",
                  }}
                >
                  <IconSvg src={iconPerson} size="large" />
                  {customer
                    ? `${firstName || ""} ${lastName || ""}`
                    : "Se connecter"}
                </ObfuscatedLink>
              </Stack>
            </Stack>
          </DesktopNavActions>
        </Stack>
        <Stack
          sx={{
            flex: "1",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 0,
            mt: "-25px",
            display: { xs: "none", md: "flex" },
          }}
          direction="row"
        >
          <Stack
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              flex: "1",
            }}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing="22px"
              sx={{ alignItems: "center", flex: 1 }}
            >
              <Stack direction="row">
                <FallbackMenuTrigger>
                  <IconButton
                    aria-label="Menu"
                    sx={{
                      mt: "2px",
                      mr: "13px",
                      color: theme.palette.primary.main,
                    }}
                    onMouseOver={() => selectionHandler()}
                  >
                    <BurgerMenu />
                  </IconButton>
                </FallbackMenuTrigger>

                <DesktopNavItem
                  underline="none"
                  sx={{
                    lineHeight: 2.8,
                    color: `${theme.palette.text.primary} !important`,
                    "&:hover": {
                      color: `${theme.palette.primary.main} !important`,
                    },
                  }}
                  key="Produits"
                  href={`/search`}
                >
                  Produits
                </DesktopNavItem>
              </Stack>
              <FormRow sx={{ flex: "1" }}>
                <SearchForm
                  search={search}
                  totalResults={0}
                  textFieldProps={{ autoFocus: true, fullWidth: true }}
                />
              </FormRow>
            </Stack>
          </Stack>

          <DesktopNavActions>
            <Stack
              marginLeft="19px"
              marginTop="-8px"
              paddingTop="1px"
              sx={{
                display: { sm: "none", md: "block" },
                justifyContent: "flex-end",
                paddingTop: "7px",
              }}
            >
              <Stack direction="row" alignItems="center">
                <WishlistFab icon={<Forklift />} />
                <CartFab icon={<ShoppingCart />} />
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{
                    marginRight: "22px !important",
                    marginLeft: "22px !important",
                  }}
                  flexItem
                />
                <Stack direction="column" paddingBottom="10px">
                  <ObfuscatedLink
                    href="/contact"
                    sx={{
                      color: theme.palette.primary.main,
                      textDecoration: "underline !important",
                      display: "flex",
                      justifyContent: "flex-end",
                      whiteSpace: "nowrap",
                      align: "left",
                      mt: "14px",
                      underline: "none",
                      mb: "4px",
                    }}
                  >
                    CONTACTEZ-NOUS
                  </ObfuscatedLink>
                  <Typography
                    component={ObfuscatedLink}
                    href="tel:0160260344"
                    sx={{
                      display: "flex",
                      fontWeight: "bold",
                      justifyContent: "flex-end",
                      whiteSpace: "nowrap",
                      color: theme.palette.text.primary,
                      textDecoration: "none",
                    }}
                  >
                    01 60 26 03 44
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </DesktopNavActions>
        </Stack>
      </Stack>
      <Stack sx={{ display: { sm: "block", md: "none" } }}>
        <StickyBar
          selectionHandler={selectionHandler}
          accountRef={customer ? "/account" : "/account/signin"}
        />
      </Stack>
    </SearchContext>
  );
}
