import { SvgIcon } from "@mui/material";

export default function Home(props) {
  return (
    <SvgIcon {...props} width="28" height="29" viewBox="0 0 28 29" fill="none">
      <g fill="white">
        <path
          d="M3.5 10.875L14 2.41666L24.5 10.875V24.1667C24.5 24.8076 24.2542 25.4223 23.8166 25.8755C23.379 26.3287 22.7855 26.5833 22.1667 26.5833H5.83333C5.21449 26.5833 4.621 26.3287 4.18342 25.8755C3.74583 25.4223 3.5 24.8076 3.5 24.1667V10.875Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 26.5833V14.5H17.5V26.5833"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
