import { SvgIcon, useTheme } from "@mui/material";

export default function ShoppingCart(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="22" height="20" viewBox="0 0 22 20" fill="none">
      <path
        d="M9 18.25C9 18.5467 8.91203 18.8367 8.7472 19.0834C8.58238 19.33 8.34811 19.5223 8.07403 19.6358C7.79994 19.7494 7.49834 19.7791 7.20736 19.7212C6.91639 19.6633 6.64912 19.5204 6.43934 19.3107C6.22956 19.1009 6.0867 18.8336 6.02882 18.5426C5.97094 18.2517 6.00065 17.9501 6.11418 17.676C6.22771 17.4019 6.41997 17.1676 6.66665 17.0028C6.91332 16.838 7.20333 16.75 7.5 16.75C7.89782 16.75 8.27936 16.908 8.56066 17.1893C8.84196 17.4706 9 17.8522 9 18.25ZM17.25 16.75C16.9533 16.75 16.6633 16.838 16.4166 17.0028C16.17 17.1676 15.9777 17.4019 15.8642 17.676C15.7506 17.9501 15.7209 18.2517 15.7788 18.5426C15.8367 18.8336 15.9796 19.1009 16.1893 19.3107C16.3991 19.5204 16.6664 19.6633 16.9574 19.7212C17.2483 19.7791 17.5499 19.7494 17.824 19.6358C18.0981 19.5223 18.3324 19.33 18.4972 19.0834C18.662 18.8367 18.75 18.5467 18.75 18.25C18.75 17.8522 18.592 17.4706 18.3107 17.1893C18.0294 16.908 17.6478 16.75 17.25 16.75ZM21.3844 4.3C21.314 4.20739 21.2233 4.13217 21.1193 4.08016C21.0153 4.02814 20.9007 4.00072 20.7844 4H4.52812L3.76875 1.3375C3.67769 1.0247 3.48788 0.749757 3.22766 0.553747C2.96744 0.357736 2.65078 0.251181 2.325 0.25H0.75C0.551088 0.25 0.360322 0.329018 0.21967 0.46967C0.0790176 0.610322 0 0.801088 0 1C0 1.19891 0.0790176 1.38968 0.21967 1.53033C0.360322 1.67098 0.551088 1.75 0.75 1.75H2.325L3.24375 4.94687V4.96563L5.71875 13.6188C5.85466 14.0883 6.13921 14.5012 6.52969 14.7953C6.92017 15.0894 7.39551 15.249 7.88437 15.25H16.8656C17.3545 15.249 17.8298 15.0894 18.2203 14.7953C18.6108 14.5012 18.8953 14.0883 19.0312 13.6188L21.5062 4.95625C21.538 4.84488 21.5435 4.72767 21.5223 4.61382C21.5012 4.49996 21.454 4.39255 21.3844 4.3Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  );
}
