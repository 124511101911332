import { extendableComponent } from "@graphcommerce/next-ui/Styles/extendableComponent";
import { Box, Link, LinkProps, useTheme } from "@mui/material";
import { useRouter } from "next/router";

const { classes, selectors } = extendableComponent("DesktopNavItem", [
  "root",
  "line",
] as const);

export type DesktopNavItemLinkProps = LinkProps<"a">;
export type DesktopNavItemButtonProps = LinkProps<"div"> & {
  onClick: LinkProps<"button">["onClick"];
};

function isLinkProps(
  props: DesktopNavItemLinkProps | DesktopNavItemButtonProps,
): props is DesktopNavItemLinkProps {
  return "href" in props;
}

export function DesktopNavItem(
  props: DesktopNavItemLinkProps | DesktopNavItemButtonProps,
) {
  const router = useRouter();
  const theme = useTheme();

  if (!isLinkProps(props)) {
    const { onClick, children, sx = [], ...linkProps } = props;

    return (
      <Link
        className={classes.root}
        component="div"
        variant="h6"
        color="text.primary"
        underline="none"
        {...linkProps}
        onClick={onClick}
        sx={[
          { whiteSpace: "nowrap", paddingTop: "6px", cursor: "pointer" },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          {children}
        </Box>
      </Link>
    );
  }

  const { href, children, sx = [], ...linkProps } = props;

  const active = router?.asPath?.startsWith((href ?? "")?.toString());

  return (
    <Link
      href={href}
      className={classes.root}
      variant="h6"
      color="text.primary"
      underline="none"
      {...linkProps}
      sx={[
        { whiteSpace: "nowrap", paddingTop: "6px" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          color: active ? theme.palette.primary.main : "",
        }}
      >
        {children}
      </Box>
    </Link>
  );
}
DesktopNavItem.selectors = selectors;
